<template>
  <div class="contents register funding" v-loading="loading">
    <div class="title flexB">
      <h2>설문 정보</h2>
    </div>
    <div class="box one">
      <div class="flexL">
        <p class="bold">
          카테고리
          <span>*</span>
        </p>
        <span>
          {{
            category == "SPECIAL"
              ? "스페셜"
              : category == "TECH"
              ? "테크/가전"
              : category == "FASHION"
              ? "패션/잡화"
              : category == "BEAUTY"
              ? "뷰티"
              : category == "FOOD"
              ? "푸드"
              : category == "HOMELIVING"
              ? "홈리빙"
              : category == "TRIP"
              ? "여행/레저"
              : category == "SPORTS"
              ? "스포츠/모빌리티"
              : category == "CULTURE"
              ? "공연/컬쳐"
              : category == "KIDS"
              ? "베이비/키즈"
              : ""
          }}</span
        >
      </div>
      <div class="flexL">
        <p class="bold">
          제품명
          <span>*</span>
        </p>
        <span>{{ productName }}</span>
      </div>
      <div class="flexL">
        <p class="bold">
          제목
          <span>*</span>
        </p>
        <span
          >alkla;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslfa;kg;lasl;fklaslfkaslf</span
        >
        <!-- <span>{{ title }}</span> -->
      </div>
      <div class="flexL">
        <p class="bold">
          설문주최
          <span>*</span>
        </p>
        <span>{{ provider }}</span>
      </div>
      <div class="flexL">
        <p class="bold">
          시작가
          <span>*</span>
        </p>
        <span>{{ numberFormat(startAmount) }}원</span>
      </div>
      <div class="flexL">
        <p class="bold">
          커트라인
          <span>*</span>
        </p>
        <span>{{ cutline }}%</span>
      </div>

      <div class="flexL">
        <p class="bold">
          설문일정
          <span>*</span>
        </p>
        <span>
          <span>{{ moment(startAt).format("YYYY-MM-DD") }}</span>
          <span v-if="endAt"> ~ {{ moment(endAt).format("YYYY-MM-DD") }}</span>
        </span>
      </div>
      <div class="flexL">
        <p class="bold">
          참여제한
          <span>*</span>
        </p>
        <span>{{ numberFormat(userCount) }}명</span>
      </div>
      <div class="flexL">
        <p class="bold">
          참여인원
          <span>*</span>
        </p>
        <span>{{ surveyers.length }}명</span>
      </div>
      <div class="flexL">
        <p class="bold">
          당첨인원 설정
          <span>*</span>
        </p>
        <span>{{ winCount }}명</span>
      </div>
      <div class="flexL">
        <p class="bold">
          쿠폰정보
          <span>*</span>
        </p>
        <span>{{ couponInfo }}</span>
      </div>
      <div class="flexL">
        <p class="bold">
          지급 포인트
          <span>*</span>
        </p>
        <span>{{ numberFormat(point) }}P</span>
      </div>

      <div class="flexL">
        <p class="bold">
          썸네일 <span>*</span>
          <br />
        </p>
        <div>
          <div class="flex" v-if="thumbnail">
            <div class="imgWrap">
              <img :src="thumbnail" alt="이미지" id="img" />
            </div>
          </div>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">
          이미지 <span>*</span>
          <br />
        </p>
        <div>
          <div class="flex" v-if="images.length > 0">
            <div v-for="(image, i) in images" :key="i" class="imgWrap">
              <img :src="image" alt="이미지" id="img" />
            </div>
          </div>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">상세 내용 <span>*</span></p>
        <pre>{{ contents }}</pre>
      </div>
      <div class="flexL">
        <p class="bold">인플루언서명</p>
        <span>{{ influencer }} </span>
      </div>
      <div class="flexL">
        <p class="bold">유튜브 url</p>
        <span>{{ youtubUrl }} </span>
      </div>
      <div class="flexL">
        <p class="bold">
          인플루언서 썸네일
          <br />
        </p>
        <div>
          <div class="flex" v-if="youtubeThumbnail.length > 0">
            <div
              v-for="(image, i) in youtubeThumbnail"
              :key="i"
              class="imgWrap"
            >
              <img :src="image" alt="이미지" id="img" />
            </div>
          </div>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">인플루언서 내용</p>
        <pre>{{ influencerContent }}</pre>
      </div>

      <div class="flexL">
        <p class="bold">
          최종가격
          <span>*</span>
        </p>
        <span>{{ numberFormat(Math.round(avgAmount)) }}원</span>
      </div>
      <!-- <div class="flexL">
        <p class="bold">
          당첨인원
          <span>*</span>
        </p>
        <ul>
          <li>손흥민(son@ulmain.com)</li>
          <li>홍길동(hong@ulmain.com)</li>
          <li>홍길동(hong@ulmain.com)</li>
          <li>홍길동(hong@ulmain.com)</li>
          <li>홍길동(hong@ulmain.com)</li>
        </ul>
      </div> -->

      <div class="buttonWrap">
        <button
          v-show="status !== 'FINISH'"
          class="basic"
          @click="handleFinish"
        >
          마감
        </button>
        <router-link to="/admin/posts">
          <button class="right line">목록</button>
        </router-link>
      </div>
    </div>
    <div class="box one">
      <div class="flexB">
        <h2 class="bold">설문 참여자 리스트</h2>
        <div class="flex">
          <p class="bold">검색조건</p>
          <select v-model="win" @change="getSurveyList">
            <option value="" disabled>선택</option>
            <option value="">전체</option>
            <option value="true">당첨회원</option>
          </select>
          <el-button
            class="line"
            type="primary"
            @click="handleDownload"
            :loading="btnDisabled"
            >다운로드</el-button
          >
        </div>
      </div>
      <table ref="surveyList">
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 25%" />
          <col style="width: 15%" />
          <col style="width: 15%" />
          <col style="width: 15%" />
          <col style="width: 10%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>이름</th>
          <th>이메일</th>
          <th>연락처</th>
          <th>참여일</th>
          <th>입력가격</th>
          <th>당첨확인</th>
        </tr>
        <tr v-for="(data, i) in surveyList" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ data.user.username }}</td>
          <td>{{ data.user.email }}</td>
          <td>{{ data.user.mobileNo }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
          <td>{{ numberFormat(data.amount) }}원</td>
          <td v-if="status == 'FINISH'">
            <span :class="{ primary: data.win }">{{
              data.win == true ? "당첨" : "미당첨"
            }}</span>
          </td>
          <td v-else>-</td>
        </tr>
      </table>
      <NoData v-if="surveyList.length == 0" />

      <div class="pagination">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="20"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchPostDetail,
  fetchSurveyList,
  fetchSurveyDumpList,
  createFinish,
} from "@/api/admin";
import moment from "moment";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";
import XLSX from "xlsx";
export default {
  name: "postDetail",
  mixins: [format],
  components: { NoData },

  data() {
    return {
      // ko: ko,
      moment: moment,
      total: 0,
      currentPage: 1,
      loading: false,
      postId: "",
      inputMode: "",
      avgAmount: "",
      startAt: "",
      endAt: "",
      tel: "",
      productName: "",
      provider: "",
      title: "",
      contents: "",
      category: "",
      startAmount: "",
      thumbnail: [],
      cutline: "",
      limitType: "",
      userCount: 0,
      winCount: "",
      couponInfo: "",
      point: "",
      influencer: "",
      youtubUrl: "",
      influencerContent: "",
      youtubeThumbnail: [],
      images: [],
      surveyList: [],
      win: "",
      status: "",
      surveyCount: 0,
      surveyers: [],
      surveyDumpList: [],
      btnDisabled: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 2);
  },
  mounted() {
    this.loading = true;
    if (this.$route.params.id) {
      this.postId = this.$route.params.id;
      this.getPost();
    }
  },
  methods: {
    getPost() {
      fetchPostDetail(this.postId).then((res) => {
        if (res.data.status == 200) {
          this.category = res.data.data.category;
          this.title = res.data.data.title;
          this.limitType = res.data.data.limitType;
          this.thumbnail = res.data.data.thumbnail;
          this.company = res.data.data.company;
          this.provider = res.data.data.provider;
          this.productName = res.data.data.productName;
          this.cutline = res.data.data.cutline;
          this.tel = res.data.data.tel;
          this.targetAmount = res.data.data.targetAmount;
          this.startAt = res.data.data.startAt;
          this.endAt = res.data.data.endAt;
          this.contents = res.data.data.contents;
          this.images = res.data.data.images;
          this.couponInfo = res.data.data.couponInfo;
          this.point = res.data.data.point;
          this.influencer = res.data.data.influencer;
          this.youtubUrl = res.data.data.youtubUrl;
          this.youtubeThumbnail = res.data.data.youtubeThumbnail;
          this.influencerContent = res.data.data.influencerContent;
          this.startAmount = res.data.data.startAmount;
          this.avgAmount = res.data.data.avgAmount;
          this.userCount = res.data.data.userCount;
          this.winCount = res.data.data.winCount;
          this.author = res.data.data.author;
          this.status = res.data.data.status;
          this.surveyCount = res.data.data.surveyCount;
          this.surveyers = res.data.data.surveyers;
          this.loading = false;
          this.getSurveyList();
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    getSurveyList() {
      let params = {
        page: this.currentPage,
        win: this.win,
      };
      fetchSurveyList(this.postId, params).then((res) => {
        if (res.data.status == 200) {
          this.surveyList = res.data.data;
          this.total = res.data.total;
          this.currentPage = res.data.page;
        } else {
          return alert(res.data.message);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPost();
    },
    handleFinish() {
      const result = confirm("설문을 마감 하시겠습니까?");
      if (result == false) {
        return;
      }
      let data = {
        postId: this.postId,
      };
      createFinish(data).then((res) => {
        if (res.data.status == 200) {
          alert("설문이 마감 되었습니다.");
          this.getPost();
        } else {
          alert(res.data.message);
        }
      });
    },
    async handleDownload() {
      this.btnDisabled = true;
      //엑셀파일로 다운로드

      let res = await fetchSurveyDumpList(this.postId);
      if (res.data.status == 200) {
        this.surveyDumpList = res.data.data;
      } else {
        return alert(res.data.message);
      }

      let surveyData = this.surveyDumpList.map((item, i) => {
        let data = {
          번호: i + 1,
          이름: item.user.username,
          이메일: item.user.email,
          연락처: item.user.mobileNo,
          생년월일: item.user.birth,
          성별: item.user.gender == "F" ? "여" : "남",
          주소: item.user.address,
          직업:
            item.user.job == "EMPLOYEE"
              ? "직장인"
              : item.user.job == "SELFEMPLOYED"
              ? "자영업"
              : "무직",
          결혼여부: item.user.isMarried == true ? "기혼" : "미혼",
          가입일: moment(item.user.createdAt).format("YYYY.MM.DD HH:mm"),
          참여일: moment(item.createdAt).format("YYYY.MM.DD HH:mm"),
          입력가격: this.numberFormat(item.amount),
          당첨확인: item.win == true ? "당첨" : "미당첨",
        };
        return data;
      });

      var excelData = XLSX.utils.json_to_sheet(surveyData); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, "설문 참여자 리스트"); // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, "surveyParticipants.xlsx"); // 엑셀파일 만듬
      this.btnDisabled = false;
    },
    // handleDelete() {
    //   removePost(this.postId).then((res) => {
    //     if (res.data.status == 200) {
    //       this.$notify({
    //         title: "Success",
    //         message: "설문이 삭제 되었습니다.",
    //         type: "success",
    //       });
    //       this.$router.push("/admin/posts");
    //     } else {
    //       alert(res.data.message);
    //     }
    //   });
    // },
  },
};
</script>
